let clientName = "CLIENT";
let productName = "PRODUCT";
/* Attention, il y a un clientName non-dynamique dans l'optin */

export const nlnlje = {

		/* Concerning the printing coupons pages */
	printCoupons: {
		page0:{
			title: "Hoe wil je jouw coupons ontvangen?",
			explanation: "Je kunt ze direct afdrukken of een e-mail ontvangen met een link waarmee je ze later kunt afdrukken. Wat wordt het?",
			optionDirectPrint: "Direct afdrukken",
			optionMailPrint: "Een e-mail ontvangen",
		}, 
		page1:{
			title: "Kortingsbon afdrukken",
			attention: "<b>Opgelet</b>, gelieve onderstaande stappen te volgen voor het afdrukken van uw kortingsbon.",
			step1: "Kijk na of je printer aan staat.",
			step2: "Is er voldoende papier en/of inkt voorradig?",
			step3: 'Klik op de knop "Mijn kortingsbon afdrukken" en je kortingsbon wordt afgedrukt.',
			conditions: "Ik aanvaard de algemene kortingsbonregels die van toepassing zijn op deze actie(s).",
			moreInfo: "Meer details",
			button: "Mijn kortingsbon afprinten"
		}
	},

	/* pageTitles are the H2 title at the TOP of each page */
	pageTitles: {
		beforeStart: "Deze actie is nog niet beschikbaar.",
		actionEnded: "Deze actie is helaas afgelopen.",
		maxReached: "Maximum aantal inzendingen bereikt",
		wrongCode: "Verkeerde code",
		wrongCode2: "Verkeerde deelname link",
		alreadySend: "Deelname reeds verstuurd",
		registration: "Inschrijving",
		confirmation: "Bevestiging",
		choiceSurvey: "",
		survey: "Enquête",
		summary: "Besteloverzicht",
		maintenance: "Onderhoud",
		form1: "Ontvang je cashback nu!",
		form2: "Wat meer informatie...", 
		wait: "Eventjes geduld…", 
		instructions: "Hoe deel te nemen",
		pageError: "Er is een fout opgetreden",
		catalog: "Catalogue",
	},

	/* sectionTitles are titles INSIDE pages */
	/* They should not be arranged by page, because they can move from one to another */
	sectionTitles: {
		wayOfRegistration: "Kies een manier om te registreren",
		thanksForRegistration: "Bedankt voor je inschrijving!",
		aboutYou: "Vertel ons meer over u",
		address: "Uw adres",
		aboutProduct: "Over ons product",
		ticketUpload: "Aankoopbewijs",
	},

	/* inputsTexts is a list for every label and helpers that Action API don't give. */
	inputsTexts: {
		gtin : {
			label: "Tapez un code-barres",
			placeholder: "0000000000000",
			helper: "Il se compose de 13 chiffres",	
		},
		listEan : {
			label: "Sélectionnez un code-barres",
			placeholder: "Choisissez un code-barres dans la liste",
			helper: " ",
		},
		dropdown : {
			placeholder: "Kies een antwoord…",
			helper: " ",
			missingData: "Het veld mag niet leeg zijn",
			errorLength: "De lengte is niet goed",
		},
		title : {
			label: "Aanspreking",
			placeholder: "Hoe kunnen we je noemen?",
			helper: " ",
		},
		gender: {
			label: "Geslacht",
			placeholder: "Hoe definieer je jezelf?",
			helper: " ",
		},
		firstname: {
			label: "Voornaam",
			placeholder : "Mijn voornaam is…",
			helper: " ",
		},
		lastname: {
			label: "Naam",
			placeholder : "Mijn naam is…",
			helper: " ",
		},
		email : {
			label: "Gelieve je e-mail in te vullen",
			placeholder: "naam{'@'}voorbeeld.be",
			helper: "Uw e-mail moet een {'@'} bevatten"
		},
		phone : {
			label: "Gelieve je GSM-nummer in te vullen",
			placeholder: "Vb: 0477123456",
			helper: " "
		},
		iban : {
			label: "Gelieve je IBAN nummer in te vullen",
			placeholder: "bv: NL01RAB01234567890",
			helper: "Het nummer moet beginnen met met de landcode (NL)"
		},
		VATNumber: {
			label: "BTW-nummer",
			placeholder: "Vb: BE0123123123",
			helper: "Het nummer moet beginnen met met de landcode (BE/LU/…)"
		},
		googleAPI:{
			label: "Gelieve je volledig adres te zoeken (inclusief huisnummer) om het formulier te kunnen valideren."
		},
		street : {
			label: "Straat",
			labelfacture: "Facturatieadres",
			placeholder: "",
			helper: " "
		},
		houseNumber: {
			label: "Nummer",
			placeholder: "",
			helper: " "
		},
		boxNumber: {
			label: "Bus",
			placeholder: "",
			helper: " "
		},
		postalCode: {
			label: "Postcode",
			placeholder: "",
			helper: " "
		},
		city: {
			label: "Gemeente",
			placeholder: "",
			helper: " "
		},
		country: {
			label: "Land",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		conditions : {
			part1 : "Ik aanvaard",
			link: "de algemene voorwaarden",
			part3: "."
		},		
		serialNumber : {
			label: "",
			placeholder: "",
			helper: " "
		},
		product: {
			label: "Welk product heb je aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		retailer: {
			label: "Waar heb je het product aangekocht?",
			placeholder: "Kies een antwoord…",
			helper: " "
		},
		freeRetailer: {
			label: `In welke winkel hebt je je ${productName} producten gekocht?`,
			placeholder: "…",
			helper: " "
		},
		freeInput: {
			label: ``,
			placeholder: "",
			helper: "",
			error_empty: "Gelieve dit veld in te vullen",
			error_wrongValue: "Het antwoord is onjuist",
		},
		datePurchase : {
			label: `Wanneer hebt je je ${productName} producten gekocht?`,
			placeholder: "jj/mm/aaaa",
			helper: `<strong>PAS OP:</strong> Gebruik de kalender om de datum in te voeren.`,
		},
		uploads: {
			ticket: {
				label: "Klik of sleep je ticket hier."
			},
			dropzone1: {
				label: "Klik of sleep een foto van de streepjescode hier.",
			},
		},
		optin : {
			part1:"Ik wil graag op de hoogte gehouden worden van ",
			linklabel:`${clientName}`,
			link:"https://www.CLIENT.be/nl/",
			part2:"promoties",
			label : "", /* Should not be visible */	
			helper : "",
			complement: `Ik wil op de hoogte blijven van toekomstige acties van ${clientName}.`
		},
		optin2 : {
			part1:`Ik stem ermee in gepersonaliseerde communicatie te ontvangen betreffende aanbiedingen, nieuws en andere promotionele initiatieven van ${clientName} en andere `,
			linklabel:`P&G-merken`,
			link:"https://nl-nl.pg.com/",
			part2:"via e-mail en online kanalen. Ik kan mij op eender welk moment",
			part3:".",
			linklabel2:`uitschrijven`,
			link2:"https://preferencecenter.pg.com/nl-nl/datarequests/",
			label : "", /* Should not be visible */
			helper : "",
			complement: `Ik wil op de hoogte blijven van toekomstige acties van ${clientName}.`
		},
		optin3 : {
			part1:"U bent minstens 18 jaar oud en stemt in met onze",
			linklabel:`Algemene voorwaarden.`,
			link:"https://termsandconditions.pg.com/nl-nl/",
		},
	},

	testPanel: {
		title: `De "verborgen" pagina's`,
		text: "Klik op de onderstaande knoppen om toegang te krijgen tot de formulierpagina's die geen deel uitmaken van de normale deelnamestroom",
		pages: {
			before: "Voor de start",
			after: "Na het einde",
			maxparticip: "Max. deelname",
			wrongcode: "Verkeerde code",
			alreadysent: "Reeds verzonden",
			maintenance: "In onderhoud",
			loading: "Laden…",
		},
		hide: "Verberg me",
		show: "Laat me zien",
	},


	/* 
	ABOVE: WHAT MUST BE USED NOW 
	BELOW: WHAT WAS USED BEFORE 
	*/







	page404: {
		title: "404",
		text: "Deze pagina bestaat niet.",
		back: "Terug naar het formulier",
	},

	errors: {
		// fields errors
		emptyField: "U moet een antwoord geven",
		dateInvalid: "De datum is niet geldig",
		dateBefore: "De datum mag geen toekomstige datum zijn",
		dateOutOfPeriod: "De aankoop moet worden gedaan binnen de geldige data voor deze actie",
		wrongcnk : "De CNK code is niet correct",
		fieldsMissing : "Kan de deelname niet verzenden omdat één of meer velden niet ingevuld/valide zijn",
		fieldsMissingTitle: "Lege/ongeldige velden",
		// modale errors, for the user
		fieldMissingValue: "Een of meer velden hebben een onjuiste of lege waarde.",
		fieldMissingValueCsq: "Gelieve het formulier in te vullen.",
		noDescription: "Geen beschrijving beschikbaar.",
		undefinedError: "Ongedefinieerde fout.",
		undefinedErrorPleaseRetry: "Er is een ongedefinieerde fout opgetreden. Probeer opnieuw.",
		maintenanceInProgress: "Onderhoud wordt uitgevoerd.",
		formUnavailable: "Probeer het later nog eens.",
		error500: "Er is een fout met de server opgetreden.",
		// Erreurs we don't need to give user too much details on, but a ref! So the user can tell us!
		// KISS Code explanation: take the first letter of each word of the variable
		countryListUnattainable: "Code: CLU",
		noFieldConfigFound: "Code: NFCF",
		participationNotSent: "Code: PNS",
		partOwnerNotCreated: "Code: PONC",
		leadNotFound: "Code: LNF",
		retailerListNotFound: "Code: RLNF",
		productCodesNotFound: "Code: PCNF",
		localizationDataNotFound: "Code: LDNF",
		codeWrongOrUnidentified: "Code: CWOU",
		mailWithCouponsNotSent: "Code: MWCNS",
		printCouponsNotWorking: "Code: PCNW",
	},

	maintenance: {
		title: "Onderhoud",
		line1 : "Deze website is momenteel in onderhoud en kan niet worden geraadpleegd. Probeer het later nog eens.",
		line2 : "Ons team doet er alles aan om de site zo snel mogelijk weer toegankelijk te maken.",
		thanks : "Dank je voor je begrip.",
	},

	macaron: {
		big: `100%`,
		small:`terugbetaald`,
	},

	footer: {
		genterms: "Algemene voorwaarden",
		privacy: {
			link: "",
			label: "Privacyverklaring",
		},
		cookies: {
			link: "",
			label: "Cookieverklaring",
		}
	},

	pgFooter: {
		conditions: "Actie voorwaarden",
		/* Below: They appears in the footer as long as there is a link value. */
		privacy: {
			link: "https://privacypolicy.pg.com/nl-NL/",
			label: "Privacy",
		},
		otherconditions: {
			link:"https://nl-nl.pg.com/",
			label:"Algemene voorwaarden"
		},
		mycoords: {
			link: "https://preferencecenter.pg.com/nl-nl/",
			label: "Mijn Gegevens",
		},
		contact: {
			link: "https://nl-nl.pg.com/contact-met-ons-opnemen/",
			label: "Contact",
		},
	},

	gdpr: {
		title: "Privacy notice",
		client: `${clientName}`,
	},

	onetrust: {
		cookie: "Beheer cookies",
	},

	navigation: {
		doSurvey: "Antwoorden op de enquête",
		skipSurvey: "Cashback aanvragen",
		previous: "Vorige pagina",
		next: "Volgende pagina",
		claimCashback: "Mijn Cashback aanvragen",
		signup: "Schrijf je nu in!",
		participate: "Deelnemen!",
		toCustomerWebsite: {
			label: "Naar onze website",
			link: ""
		},
		readMoreOnWebsite: "Lees meer",
	},

	breadcrumbSteps: {
		registration: "Inschrijven",
		store: "Doe je aankopen",
		form: "Vul het formulier in",
		survey: "Vul een optionele vragenlijst in",
		cashback: "Ontvang je terugbetaling"
	},

	formHeader: {
		bottomText: `* Bij aankoop van Lorem ipsum`,
		macaronText: `terugbetaald!`,
		mainTitle: "Schrijf je in en ontvang je cashback!",
		altText: "banner"
	},

















	pageLitteracy: {
		pageError: {
			intro: "Er is een fout opgetreden waardoor het formulier niet werkt.",
			solution1 : "Probeer het formulier opnieuw te laden of probeer het later opnieuw..",
			solution2 : `Als de fout blijft bestaan, neem dan contact op met Promolife via <a href="mailto:infolijn{'@'}promolife.be">infolijn{'@'}promolife.be</a> ou au <a href="tel:3223520390">+32 2 352 03 90</a>`,
		},
		instructions : {
			intro: "Instructions de participation comme dans une HAv1. Pas de breadcrumb ici, sinon double emploi (icones, textes).",
			steps: {
				info : "Informatie pagina",
				register: "Als je van plan bent op een later tijdstip deel te nemen, registreer je dan nu, zodat je een herinnering ontvangt.",
				store: {
					line1a: "Haast je naar de winkel tussen",
					line1b: "en",
					line2: "Koop een MARQUEMACHIN product(en).",
					line3: "Houd je kassaticket of factuur goed bij.",
				},
				form: {
					line1: "Vul je gegevens in.",
					line2: "Neem het aankoopbewijs van je MARQUEMACHIN product(en) erbij en omcirkel de datum, het aangekocht product en de prijs.",
					line3: "Scan of neem een foto van je volledig aankoopbewijs.",
					line4: "Upload deze foto of scan.",
				},
				survey: "Doe een optionele enquête en ontvang een kortingsbon.",
				cashback: "Eens we je deelname goedgekeurd hebben, ontvangt je je terugbetaling binnen de 6 weken.",
			}
		},
		maxReached : "Het maximale aantal deelnames is bereikt. Deze actie is daarom niet meer toegankelijk.",	
		wrongCode: {
			text: `De opgegeven code is verkeerd. Je moet de link gebruiken die je door ${clientName} wordt toegestuurd.`,
			textregistration: "Maar misschien wili je je inschrijven voor de actie?",
			errorWithReparticipation: `Er is een fout opgetreden met je deelname link.<br>Neem contact op met onze technische teams door een e-mail te sturen naar <a href="mailto:infolijn{'@'}promolife.be">infolijn{'@'}promolife.be</a> met onderstaande informatie:`
			/* ! Missing translation */
		},
		alreadySend: "U heeft je deelname al verzonden",
		registration : {
			byRegistering: "Door me in te schrijven voor deze actie, ga ik akkoord met de",
			privacyNotice: "Privacy notice",
			claimMail : "Inschrijven via e-mail",
			claimWhatsapp: "Inschrijven via Whatsapp",
			claimSms: "Inschrijven via SMS",
			or: "Of",
			whatsappTitle: "Klik op de Whatsapp knop",
			whatsappInfo: {
				part1: `Een venster wordt geopend en je wordt gevraagd om het woord`,
				part2: `van uit je Whatsapp te verzenden.`
			},
		},
		confirmRegistration: {
			smsLine1 :"U zal spoedig een SMS ontvangen met alle details.",
			smsLine2: "Ga eens kijken!",
			whatsappLine1: "Controleer je telefoon.",
			whatsappLine2: "Alle instructies zijn verstuurd via Whatsapp",
			mailLine1: "U zal spoedig een e-mail ontvangen met alle details.",
			mailLine2: "Ga eens kijken!",
		},
		form1: {
			legal: ``,
		},
		choiceSurvey: {
			title:'',
			fillSurveyIncite: "Neem deel aan onze enquête en krijg een e-coupon van €1 voor je volgende aankoop",
		},
		survey: {
			title: "Bedankt voor het beantwoorden van deze vragen",
			about: "Enquête",
		},
		confirmation: {
			title: "Bevestiging",
			textes: {
				p1: `Wij danken je voor je deelname aan de ${clientName} actie.`,
				p2: "U ontvangt je terugbetaling binnen de 6 weken, mits je aan de algemene voorwaarden heeft voldaan.",
				coupon: "We hebben je kortingsbon naar het opgegeven e-mailadres verzonden. Geniet ervan!",
				p3: "Met vriendelijke groeten,",
				p4: `Het ${clientName} team`
			},
		},
		actionEnded: {
			text: "Bezoek onze website regelmatig om op de hoogte te blijven van toekomstige acties."
		},
		beforeStart: {
			text: "U krijgt toegang vanaf ",
			shouldBeginSoon: "De actie moet spoedig beginnen. Kom later nog eens terug. "
		},
	},

	popUp: {
		title: "Relevante reclame-informatie en gepersonaliseerde online reclame",
		text: "Het is ons doel om uw ervaring met ons en de merken van Procter & Gamble interessant, aangenaam en gepersonaliseerd te maken. Dit doen we door onze commerciële communicatie af te stemmen op uw interesses en u niets te sturen dat u niet leuk vindt of u geen reclame te tonen die u al talloze keren hebt bekeken. Hoe doen we dit?",
		ul:{
			li1:"Het combineren van gegevens om onze communicatie beter af te stemmen op uw interesses. Op basis van ons legitieme belang kunnen wij uw registratiegegevens en de gegevens die u ons verstrekt wanneer u onze diensten gebruikt (merkvoorkeuren, uitgeknipte bonnen, enz.) aanvullen met kenmerken, interesses en demografische gegevens uit commercieel beschikbare bronnen of van andere derden. Evenzo geldt het volgende: als u hebt ingestemd met onze advertentiecookies of soortgelijke technologieën op websites of apps van Procter & Gamble of, in sommige gevallen, op websites of apps van derden, kunnen we de vergaarde gegevens aan de hand van deze advertentiecookies aanvullen met andere informatie die u ons verstrekt bij het aanmaken van een account of het gebruiken van onze diensten.",
			li2:"We zullen die gegevens verwerken, ook op automatische wijze, om onze content en aanbiedingen via e-mail, online (bijvoorbeeld op externe platformen, waaronder socialmediaplatformen) en offline (bijvoorbeeld via papieren post) te personaliseren als u ermee hebt ingestemd commerciële communicatie van ons te ontvangen.",
			li3:"Reclame voor soortgelijke personen. Op basis van ons legitieme belang zullen we uw gegevens ook analyseren om nieuwe contactpersonen te identificeren die een soortgelijk profiel hebben en mogelijk graag van ons zouden horen. Wijzelf en onze Procter & Gamble-partners kunnen bijvoorbeeld uw gehashte e-mailadres in Facebook uploaden en Facebook vragen onze reclame voor te schotelen aan personen met soortgelijke interesses, ook in andere landen, op basis van de gegevens die Facebook over u en andere personen bezit.",
			li4:"Het gebruik van uw persoonsgegevens om via online media contact met u op te nemen. Als u ermee hebt ingestemd om commerciële communicatie van ons te ontvangen, zullen we uw gehashte persoonsgegevens (d.w.z. uw e-mailadres, telefoonnummer of mobiele reclame-ID) gebruiken om u via online kanalen (bijv. uw sociale media account) reclame toe te sturen. Wij gebruiken hiervoor een proces waarbij uw privacy in acht wordt genomen, aan de hand van versleutelde gegevens en zorgvuldig geselecteerde partners. Concreet zullen we uw persoonsgegevens delen met derden die ons reclamediensten op maat verstrekken, waaronder via sociale media platformen. Wij gebruiken die diensten om de gehashte informatie die wij bezitten te matchen met de persoonsgegevens in hun database om aangepaste doelgroepen te creëren en reclame af te stemmen op uw interesses op het internet, waaronder sociale media, voor zover dit wettelijk is toegestaan.",
			li5:"Zodoende kunnen wij u relevantere online reclame (aanbiedingen, informatie over producten en innovaties) sturen en u minder reclameboodschappen voorschotelen. We weten immers welke reclame u al hebt bekeken en over welke onderwerpen we niet langer hoeven te communiceren. De meeste van onze klanten waarderen dat we hen geen reclame willen tonen over producten waar ze niet om geven of die we hen al eerder hebben getoond. Geldt dit niet voor u? Geen probleem! U kunt eender wanneer uw voorkeuren aanpassen.",
			li6:" kunt u altijd van gedacht veranderen en uw toestemming intrekken/uitschrijven voor het gebruik van uw gegevens voor gepersonaliseerde communicatie en reclame, waaronder de creatie van aangepaste en soortgelijke doelgroepen.",
			li6Link: "https://preferencecenter.pg.com/nl-nl/datarequests/",
			li6LinkLabel: "Hier",
			li6Link2: "https://privacypolicy.pg.com/nl-nl/",
			li6LinkLabel2: "Lees ons privacybeleid.",
		}
	},

	popUp2: {
		title: "Wij hechten veel waarde aan uw privacy",
		ul:{
			li1:"Ons Privacybeleid regelt de verzameling en het gebruik van uw gegevens",
			li2:"Dit is de reden",
			li2Link: "https://privacypolicy.pg.com/nl-nl/",
			li2LinkLabel:"waarom",
			li2Part2:"wij uw gegevens verzamelen, onze rechtsgrondslagen voor de verwerking, en de periode gedurende welke uw persoonsgegevens worden opgeslagen.",
			li3:"Onze e-mails bevatten pixels die P&G helpen te begrijpen of u interactie met hen hebt en welke inhoud interessant voor u was.",
			li3Link:"https://privacypolicy.pg.com/nl-nl/",
			li3LinkLabel:"Lees hier",
			li3Link2:"https://preferencecenter.pg.com/nl-nl/datarequests/",
			li3LinkLabel2:"afmelden",
			li3Part2:"meer. U kunt zich op elk gewenst moment",
			li3Part3:"voor onze e-mailcommunicatieprogramma's.",
			li4:"Uw gegevens kunnen in een ander land verwerkt worden dan het land waarin ze verzameld zijn, inclusief de Verenigde Staten.",
			li4Link:"https://privacypolicy.pg.com/nl-nl/",
			li4LinkLabel:"Lees hier",
			li4Part2:"meer.",
			li5:"U kunt uw rechten als betrokkene uitoefenen (u kunt de persoonsgegevens die wij van u hebben inzien, corrigeren, wissen, overdragen aan een andere provider, bezwaar maken tegen of de verwerking beperken, of uw toestemming intrekken) of uw voorkeuren en abonnementen wijzigen door",
			li5Link:"https://preferencecenter.pg.com/nl-nl/datarequests/",
			li5LinkLabel:"hier",
			li5Part2:"te klikken.",
		}
	},

	PGLegal: {
		title: "Waarom verzamelen wij deze gegevens?",
		mainText:{
			personalData: "Procter&Gamble, de verwerkingsverantwoordelijke, zal uw persoonsgegevens verwerken zodat u zich op deze website kunt registreren, de diensten ervan kunt gebruiken en, naargelang uw toestemming, relevante commerciële communicatie, waaronder gepersonaliseerde reclame in online media, kunt ontvangen.",
			moreInfoLinkLabel: "Meer informatie",
			privacyInfoText: "Voor meer informatie inzake de verwerking van uw gegevens en uw privacyrechten, lees",
			privacyLinkLabel: "hier",
			privacyInfoText2: "of raadpleeg ons volledige ",
			privacyPolicyLinkLabel: "Privacybeleid",
			privacyLink: "https://privacypolicy.pg.com/nl-BE/",
			privacyInfoText3: ".",
		}
	},

	counterText: {
		participationLeft : {
			before: "Nog",
			after: "deelnames beschikbaar",
		},
		daysLeft: {
			before: "U hebt nog",
			after: "dagen om deel te nemen!"
		}
	},







	inputLabel: {
		defaultListHelper: "Kies er één uit de lijst",
		mandatoryFields: "Verplichte velden",
		noResult: "Geen resultaat",





		empty : {
			label: "",
			placeholder: "",
			helper: ""
		},
		cnk1 : {
			label: `Wat is de CNK code van het gekochte ${clientName} product?`,
			placeholder: "0000-000",
			helper: "Het format is: XXXX-XXX",
			error: "De CNK code is niet correct",
		},



      //https://vue-i18n.intlify.dev/guide/essentials/syntax.html#literal-interpolation

/*
      typePassword : "Gelieve je wachtwoord in te vullen",
      helpPassword : "Uw wachtwoord moet X, Y en Z bevatten",
      typeUniqueCode : "Indiquez ici le code visible sur le ticket de caisse",
      */

		upload: {
			state: "status",
			uploadYourFile: "Upload bestand",
			clickOrDrop: "Klik of sleep je ticket hier.",
			rules: "Afbeelding moet een bestand zijn van het bestandstype JPG, JPEG, PNG, BMP, GIF, SVG, WEBP, PDF",
			remove: "Verwijder",
			waiting: "Wachten op upload...",
			uploaded: "Geüpload!",
			error: "Mislukt",
			uploadsDone: "Upload succesvol afgerond",
			onlyImages: "Enkel afbeeldingen worden aanvaard.",
			maxSize: "Maximale grootte van 20 MB",
			uploadFile: "Upload bestand",
			generalError: "Er ging iets mis met je upload, gelieve later opnieuw te proberen"
		}
	},
}
